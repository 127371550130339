.btn {
  border-radius: 8px;
  background-color: transparent;
  font-size: 18px;
  overflow: hidden;
  line-height: 20px;
  /* border: 1px solid #1986c9; */
  border: 1px solid var(--primary);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  /* color: #1986c9; */
  color: var(--primary);
  padding: 14px 40px;
  transition: 0.3s all ease-out;
  position: relative;
}

.btn-primary {
  background-image: linear-gradient(to right, #254089, #1986c9);
  color: var(--white) !important;
  border: none;
}
.btn-danger {
  background-color: var(--warn) !important;
  color: var(--white) !important;
  border-color: var(--warn) !important;
}
.btn-success {
  background-color: var(--success) !important;
  color: var(--white) !important;
  border-color: var(--success) !important;
}

.btn-primary-border {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-secondary {
  background-color: var(--secondary);
  color: var(--white);
  border-color: var(--secondary);
}

.btn-secondary-border {
  color: var(--secondary);
  border-color: var(--secondary);
}

.btn-white {
  background-color: var(--white);
  color: var(--primary);
  border-color: var(--primary);
  color: #1986c9;
  border-color: #1986c9;
}

.btn-white:hover {
  box-shadow: 0px 8px 16px 0 rgba(0, 153, 46, 0.25);
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: inherit !important;
  box-shadow: none !important;
}
.btn:disabled::before {
  content: none;
}

.btn:hover {
  box-shadow: 0px 8px 16px 0 rgba(0, 153, 46, 0.25);
}

.btn:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: -1;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}

.btn:hover:before {
  z-index: 1;
  animation: imageH 1s;
  -webkit-animation: imageH 1s;
  -moz-animation: imageH 1s;
  -ms-animation: imageH 1s;
  -o-animation: imageH 1s;
}

@-webkit-keyframes imageH {
  100% {
    left: 125%;
  }
}

@keyframes imageH {
  100% {
    left: 125%;
  }
}

.border-link {
  position: relative;
}

.border-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  background-color: white;
  height: 2px;
  transform: scaleX(0) translateZ(0);
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.border-link:hover:before {
  transform: scaleX(1) translateZ(0);
  transform-origin: 0 0;
}

.pulse_effect {
  display: none;
}

@media screen and (min-width: 1280px) {
  .pulse_effect {
    border-radius: 50%;
    position: absolute;
    height: 40px;
    width: 40px;
    left: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    z-index: -1;
    display: block;
  }

  .pulse_effect:after {
    content: "";
    border-radius: 50%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 25px 2px #db429c;
    animation-delay: 1s;
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}
