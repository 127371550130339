.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
}

.leftPortion {
    width: 50%;
    position: relative;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f9ff;
    user-select: none;
}
.leftPortion .banner-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.leftPortion .websiteLogo {
    position: absolute;
    top: 5%;
    left: 5%;
}

.rightPortion {
    width: 50%;
    padding: 30px;
    text-align: left;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 768px) {
    .leftPortion {
        width: 100%;
    }
    .rightPortion {
        width: 100%;
    }
}
.rightPortion .loginWrapper {
    width: 500px;
    max-width: 90%;
}
.back-icon{
    display: inline-block;
}
.back-icon img {
    width: 10px;
    object-fit: cover;
    cursor: pointer;
}

.loginWrapper h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
}

.loginWrapper h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
}

.formIn .formItem {
    margin-bottom: 35px;
}

.formIn .formItem label {
    color: #254089;
    opacity: 0.74;
}

.formItem.form {
    position: relative;
}

.formItem .eye-icon {
    position: absolute;
    top: 18px;
    right: 24px;
}

.formItem .eye-icon button.eye-visible {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
}

.btn-password {
    text-align: right;
}

.btn-password a {
    color: #254089;
}

.btn-password button.forgot-pass {
    color: #254089;
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;
    border-style: none;
    cursor: pointer;
    background-color: transparent;
    padding-bottom: 39px;
}

.formItem .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #254089;
}

/* .formItem .MuiFormControl-root.MuiTextField-root.signform.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
} */

/* FORGOT PASSWORD */
.logintitle.forgot-pass-title {
    padding-bottom: 117px;
}

.btn-primary.forgot-pass-btn {
    padding-top: 126px;
}

/* RESET PASSWORD */
.btn-primary.reset-pass-btn {
    padding-top: 20px;
}

.formItem input#email {
    color: #254089;
}

input#outlined-password-input {
    color: #254089;
}

.logintitle.reset-pass-title {
    margin: 0;
    padding-bottom: 69px;
}
