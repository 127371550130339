.formItem.add-details-info.add-subs {
  margin-bottom: 50px;
  margin-top: 0;
}

.add-details-info-wrapper {
  padding-bottom: 50px;
}

.back-link img {
  width: 10px;
  margin-right: 14px;
  cursor: pointer;
}

div.add {
  color: #2d2fc1;
  text-decoration: underline;
  cursor: pointer;
  float: left;
  font-weight: 500;
}

.item {
  margin-top: 24px;
}

.text-plus-button:not(:last-child) {
  margin-bottom: 20px;
}
.text-plus-button .MuiFormControl-root {
  width: calc(100% - 76px);
}
.text-plus-button .btn {
  width: 56px;
  height: 56px;
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 40px; */
}
.text-plus-button .btn .MuiSvgIcon-root {
  font-size: 30px;
}
.for-subs {
  max-width: inherit !important;
  overflow: inherit !important;
  white-space: normal !important;
  text-overflow: inherit !important;
}
.for-subs p:not(:last-child) {
  margin-bottom: 5px;
}

.add-link-cover {
  display: inline-block;
  text-align: right;
  width: 100%;
}
.add-link-cover p {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  color: #254089;
}
