:root {
    --primary: #254089;
    --secondary: #fd7121;
    --primary-blue: #5282F0;

    --bgLighGray: #fafafa;

    --input-border: #a5a5a5;
    --input-bg: #ffffff;
    --border-color: #cedceb;

    --lightgrayLabel: rgb(0 0 0 / 87%);
    --red: #ff5722;
    --green: #4aaf39;
    --orange: #fb8c00;
    --info: #25a9e0;
    --light-gray: #eaeaea;
    --warning: #ffc107;
    --status-green: #1bc5bd;
    --status-red: #f64e60;
    --warn: #f44336;
    --error: #ce0101;
    --success: #0d8c69;

    --white: #ffffff;
    --black: #202020;
    --yellow: #e8e759;
    --blue: #3073b9;
    --gray: #979797;
    --grey: #44464a;
    --regentGray: #8d98a1;
    --link: #e17582;
    --lightBlue: #1b61b6;
    --silver: #c7c9cb;
    --lightGreen: #e5ffe8;
    --pink: #ffecec;
    --textColor: #333333;
    --alto: #d3d3d3;
    --darkGray: #585858;
    --btnBorder: #9e9e9e;
    --seaGreen: #08575b;
    --purple: #3e3f5e;
    --infoColor: #4f95cc;
    --boulder: #757575;
    --pinkSwan: #beb4b4;
    --submarine: #b5bdc2;
    --lightGrey: #93a1b5;
}