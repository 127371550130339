.counters-list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0px -15px;
}
.counters-list .counter-card {
    margin: 15px;
    padding: 15px;
    /* background-image: linear-gradient(to bottom right, #254089, #1986c9); */
    background-color: #488aa5;
    color: white;
    border-radius: 5px;
    width: calc(25% - 30px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}
.counters-list .counter-card h1 {
    margin-top: 10px;
    font-weight: bold;
}