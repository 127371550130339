.comp-detail-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* padding: 20px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
    border-radius: 0px; */
}

@media screen and (max-width: 1400px) {
    .comp-detail-wrapper {
        flex-direction: column;
    }
}

.page-comp-detail .comp-detail-wrapper .property-image-cover {
    width: 550px;
    height: 250px;
    position: relative;
}
.page-comp-detail .comp-detail-wrapper .property-image-cover figure{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1500px) {
    .page-comp-detail .comp-detail-wrapper .property-image-cover {
        width: 400px;
    }
}

@media screen and (max-width: 1400px) {
    .page-comp-detail .comp-detail-wrapper .property-image-cover {
        margin: auto;
    }
}

.page-comp-detail .comp-detail-wrapper .property-image-cover figure img.prop-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.page-comp-detail .comp-detail-wrapper .property-image-cover .image-content {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-comp-detail .comp-detail-wrapper .property-image-cover .image-content h3 {
    text-transform: uppercase;
    border-radius: 20px;
    color: var(--primary);
    background-color: var(--white);
    padding: 5px 20px;
    font-weight: bold;
}

.page-comp-detail .comp-detail-wrapper .property-image-cover .image-content img:first-child {
    padding-right: 30px;
    border-right: 1px solid #eeeeee;
    margin-right: 30px;
}

.page-comp-detail .comp-detail-wrapper .comp-detail-tabs {
    flex: 1;
    padding-left: 25px;
}
.page-comp-detail .comp-detail-wrapper .comp-detail-tabs .MuiTabs-root{
    border-bottom: 1px solid #d9d9d9;
}

@media screen and (max-width: 1400px) {
    .page-comp-detail .comp-detail-wrapper .comp-detail-tabs {
        padding-left: 0px;
        padding-top: 20px;
        width: 100%;
    }

}

.dashboard-btn.comp-details-btn {
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
    margin-bottom: 20px;
}

.dashboard-btn.comp-details-btn>div {
    background-color: var(--primary);
    padding: 5px;
    border-radius: 5px;
}

.comp-icons {
    position: absolute;
    top: 25px;
    left: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comp-icons .lease-content>span {
    border-radius: 100px;
    background: rgba(82, 130, 240, 0.6);
    padding: 10px 15px;
}

.comp-icons .lease-content>span a {
    color: #ffffff;
}

.comp-icons .lease-icon span a img {
    margin-right: 22px;
}

.comp-icons .lease-icon span:first-child a {
    border-right: 0.2px solid var(--primary);
}

.comp-icons .lease-icon span:last-child {
    margin-left: 22px;
}

.comp-detail-tabs {
    flex: 0 0 60%;
    padding-left: 20px;
}

.comp-detail-tabs .MuiTabs-flexContainer .MuiTab-root {
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
}

.comp-detail-tabs .MuiTabs-flexContainer {
    justify-content: space-between;
    font-weight: bold;
}

ul.comp-detail-tabs,
ul.address-information-card,
ul.basic-information-card,
ul.details-card {
    padding-top: 40px;
}

ul.details-card:last-child {
    padding-top: 0;
}

ul.comp-detail-tabs li,
ul.address-information-card li,
ul.basic-information-card li,
ul.details-card li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid var(--primary);
    padding-bottom: 10px;
    margin-bottom: 30px;
}

ul.address-information-card li label,
.basic-information-card li label,
.details-card li label {
    color: var(--primary);
    font-weight: 700;
    font-size: 18px;
}

ul.address-information-card li p,
ul.basic-information-card li p,
ul.details-card li p {
    font-weight: 400;
    font-size: 18px;
    color: var(--primary);
    opacity: 0.7;
}

ul.parking-detail-wrapper {
    color: var(--primary);
    list-style-type: none;
}

ul.parking-detail-wrapper .parking-title {
    text-align: left;
    font-weight: 700;
    font-size: 20px;
}

.parking-info {
    background: #5282f012;
    padding: 25px;
}


.landlords-info,
.tenants-info {
    display: flex;
}

.landlord-card,
.tenants-card {
    background-color: var(--primary);
    opacity: 0.7;
    border-radius: 15px;
    margin: 0 10px;
    padding: 15px;
    text-align: center;
    width: calc(100% / 3);
    box-shadow: 0px 5px 6px 1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    cursor: pointer;
}

.landlord-card:first-child,
.tenants-card:first-child {
    position: relative;
    margin-left: 0;
}

.landlord-card:last-child,
.tenants-card:last-child {
    margin-right: 0;
}

.landlord-card h4,
.tenants-card h4 {
    color: var(--primary);
    background-color: #ffffff;
    border-radius: 50px;
    text-align: center;
    width: 50px;
    padding: 2px;
    font-weight: 400;
    font-size: 14px;
}

.landlord-card h5,
.tenants-card h5 {
    font-weight: 700;
    font-size: 20px;
    color: var(--white);
    padding-bottom: 15px;
    padding-top: 20px;
}

.landlord-card h6,
.tenants-card h6 {
    font-weight: 700;
    font-size: 0.9vw;
    color: var(--white);
    opacity: 0.7;
}

.landlord-card figure,
.tenants-card figure {
    margin: 0 auto;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
}

.landlord-card figure img,
.tenants-card figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landlords-company {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 0.5px solid var(--primary);
    padding-top: 100px;
}

.landlords-company h2 {
    font-weight: 700;
    font-size: 20px;
    color: var(--primary);
}

.landlords-company p {
    color: var(--primary);
    opacity: 0.7;
    font-weight: 400;
    font-size: 20px;
}