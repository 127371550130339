.dashboardContainer {
    max-width: 100%;
    background-color: #2540890d;
    padding: 30px;
    margin-bottom: 50px;
}

.dashboard-title-section {
    text-align: left;
}

.dashboard-title h2 {
    color: var(--black);
    font-weight: 700;
    font-size: 25px;
}

.back-link {
    display: flex;
    align-items: center;
    padding-right: 10px;
    border-bottom: 0;
    align-items: baseline;
}
.back-link img {
    width: 10px;
    margin-right: 14px;
    cursor: pointer;
}

.btn-link {
    display: flex;
    justify-content: space-between;
}

.dashboard-title-section .dashboard-btn {
    margin: 0;
}

.formItem.add-details-info {
    width: 745px;
    max-width: 100%;
    margin: 50px 0;
}

.formItem.add-details-info label#outlined-basic-label {
    color: rgb(0 0 0 / 45%);
    font-weight: 400;
    font-size: 18px;
    opacity: 0.7;
}

.formItem.add-details-info
    label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: rgb(0 0 0 / 45%);
    font-weight: 400;
    font-size: 18px;
    opacity: 0.7;
}

.formItem.add-details-info textarea {
    width: 100%;
    border-radius: 6px;
}

.formItem.add-details-info textarea:focus {
    border-color: var(--primary);
}

.formItem.add-details-info textarea::placeholder {
    color: rgb(0 0 0 / 45%);
    font-weight: 400;
    font-size: 18px;
    opacity: 0.7;
    font-family: "Segoe UI", "Roboto", sans-serif;
    padding-left: 15px;
    padding-top: 10px;
}

.radio-buttons-wrapper {
    padding-bottom: 50px;
    display: flex;
    align-items: center;
}

.radio-label {
    min-width: 258px;
}

/* HEADER */

header {
    display: flex;
    /* background-color: var(--primary); */
    background-image: linear-gradient(to right, #254089, #1986c9);
    justify-content: space-between;
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 999;
}

.website-logo {
    margin: 20px 0;
    padding-left: 50px;
}

.profile-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid var(--white);
    margin-left: 10px;
  }

.right-header {
    display: flex;
    align-items: center;
}

.profile-detail {
    padding-right: 29px;
    border-right: 2px solid var(--white);
}

.profile-detail img {
    cursor: pointer;
}

.logout {
    padding-left: 29px;
    padding-right: 59px;
    color: var(--white);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logout h2 {
    font-weight: 700;
    font-size: 20px;
    margin-right: 10px;
}

/* ASIDE SECTION */
.main-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: #2540890d;
    padding-top: 71px;
}
main {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 71px);
}
main .main-content {
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
}
aside {
    /* background-color: var(--white); */
    background-image: linear-gradient(to bottom right, #254089, #1986c9);
    width: 330px;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 71px);
    padding: 25px 15px;
    border-top: 1px solid #4879a8;
}
aside ul {
    padding: 0;
}
aside ul li {
    list-style-type: none;
    text-align: left;
    border-radius: 7px;
    transition: 0.2s;
}

aside ul li:not(:last-child) {
    margin-bottom: 5px;
}

aside ul li:hover {
    background-color: #00000036;
}

aside ul li.active {
    background-color: var(--white);
}

aside ul li.active a {
    color: var(--primary);
}

aside ul li a {
    text-decoration: none;
    color: var(--white);
    padding: 14px 17px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 0;
}
aside ul li.disable a {
    opacity: .5;
}
aside ul li.disable:hover {
    background-color: transparent !important;
}
aside ul li.disable:hover a{
    cursor: not-allowed !important;
}

@media screen and (min-width: 1600px) {
    aside {
        width: 350px;
    }
    aside ul li a {
        font-size: 18px;
    }
}

aside ul li a img {
    margin-right: 20px;
}
