.page-reports-analytics .space-between {
  margin: -10px -10px 15px -10px;
  flex-wrap: wrap;
}
.page-reports-analytics .space-between .btn {
  width: calc(25% - 20px);
  min-width: fit-content;
  margin: 10px;
}
.page-reports-analytics .table-cover thead tr th {
  white-space: initial;
  min-width: 120px;
}

.page-reports-analytics .inactive-user td{
  background-color: #9934332e !important;
}
.page-reports-analytics .table-cover tbody tr td {
  cursor: default !important;
}

.page-reports-analytics .table-cover {
  max-height: calc(100vh - 350px);
}
.page-reports-analytics .sorting-icon {
  opacity: 0.7;
}
.page-reports-analytics .sorting-icon .MuiSvgIcon-root {
  opacity: 1 !important;
  font-size: 24px;
  color: white !important;
}
.page-reports-analytics .sorting-icon:hover {
  opacity: 1;
}
.page-reports-analytics .sorting-icon.Mui-active {
  opacity: 1;
}
