/* LISTINGS */
.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 45px;
}

.search-and-switch {
    display: flex;
    align-items: center;
}
.btn-filter {
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px !important;
    width: 50px;
    height: 50px;
}

.auto-approved-button p {
    font-weight: 700;
    font-size: 22px;
    color: var(--primary);
    font-style: italic;
}

.search {
    width: 500px;
    max-width: 100%;
}
@media screen and (max-width: 1500px) {
    .search {
        width: 400px;
    }
}

.table-cover {
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    overflow: auto;
    max-width: 100%;
    max-height: calc(100vh - 340px);
    min-height: 300px;
    position: relative;
}

.table-cover thead tr th {
    text-align: center;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 400;
    font-size: 18px;
    white-space: nowrap;
}

.table-cover tbody tr td {
    text-align: center;
    color: var(--lightgrayLabel);
    font-weight: 400;
    font-size: 16px;
    max-width: 300px;
    min-width: 150px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: .2s;
}
.table-cover tbody tr:hover td {
    background-color: #ecf6ff;
}

.table-cover tbody tr th {
    text-align: center;
    color: var(--lightgrayLabel);
    font-weight: 400;
    font-size: 16px;
}

.pagination-cover {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.pagination-cover .MuiButtonBase-root.Mui-selected {
    background-color: var(--primary);
    color: var(--white);
}
.pagination-cover .MuiButtonBase-root.Mui-selected:hover {
    background-color: var(--primary);
}

.auto-approved-button span {
    padding: 0;
}
