.broker-detail-wrapper {
  display: flex;
  justify-content: space-between;
}

.broker-back-btn {
  flex-shrink: 0;
}

.broker-back-btn a {
  margin-right: 10px;
}

.broker-back-btn > span {
  font-weight: 700;
  font-size: 22px;
  color: #254089;
}

.dashboard-btn Button:last-child {
  margin-right: 0;
}

.dashboardContainer.profile-box {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.dashboardContainer.profile-box .profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 70px;
}

.profile-image figure {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.profile-image figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.completed-tasks-wrapper {
  display: flex;
  flex: 1;
}

.completed-tasks-wrapper .tasks-completed {
  width: calc(100% / 3);
  padding-right: 60px;
  text-align: center;
}

.tasks-completed h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary);
  padding-bottom: 30px;
  cursor: default;
}

.tasks-completed h4 {
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
}

.dashboardContainer.lic h3 {
  margin-bottom: 10px;
}
.dashboardContainer.lic h3 img {
  margin-left: 6px;
}

.subscription-title h6 {
  font-weight: 700;
  font-size: 14px;
  color: var(--primary);
  text-decoration-line: underline;
  font-style: italic;
  padding-top: 10px;
}

/* .dashboardContainer.lic .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3.css-1equabv-MuiGrid-root {
    padding-bottom: 61px;
} */

.lic-info {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 25px;
}
.lic-info-card:not(:first-child) {
  padding-left: 20px;
}
.lic-info-card:not(:last-child) {
  padding-right: 20px;
  border-right: 1px solid var(--primary);
}

.lic-wrapper {
  display: flex;
  flex-direction: column;
}

.lic-wrapper .lic-info .lic-info-card h5 {
  font-weight: 700;
  font-size: 16px;
  color: var(--primary);
}

.lic-wrapper .lic-info .lic-info-card h6 {
  font-weight: 700;
  font-size: 14px;
  color: var(--primary);
  padding-bottom: 10px;
}
.MuiTabs-flexContainer .MuiButtonBase-root {
  font-size: 18px;
  padding: 20px 16px;
  text-transform: capitalize;
}
