h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1.5;
    margin: 0px;
}

h1 {
    font-size: 22px;
}
@media screen and (min-width: 1600px) {
    h1 {
        font-size: 24px;
    }
}
@media screen and (max-width: 576px) {
    h1 {
        font-size: 20px;
    }
}

h2 {
    font-size: 20px;
}
@media screen and (min-width: 1600px) {
    h2 {
        font-size: 22px;
    }
}
@media screen and (max-width: 576px) {
    h2 {
        font-size: 18px;
    }
}

h3 {
    font-size: 18px;
}
@media screen and (min-width: 1600px) {
    h3 {
        font-size: 20px;
    }
}
@media screen and (max-width: 576px) {
    h3 {
        font-size: 16px;
    }
}

h4 {
    font-size: 16px;
}
@media screen and (min-width: 1600px) {
    h4 {
        font-size: 18px;
    }
}
@media screen and (max-width: 576px) {
    h4 {
        font-size: 14px;
    }
}

h5 {
    font-size: 14px;
}
@media screen and (min-width: 1600px) {
    h5 {
        font-size: 16px;
    }
}
@media screen and (max-width: 576px) {
    h5 {
        font-size: 12px;
    }
}

h6 {
    font-size: 12px;
}
@media screen and (min-width: 1600px) {
    h6 {
        font-size: 14px;
    }
}
@media screen and (max-width: 576px) {
    h6 {
        font-size: 10px;
    }
}

p {
    font-size: 16px;
    margin-top: 0;
    line-height: 1.5;
}
@media screen and (min-width: 1600px) {
    p {
        font-size: 18px;
    }
}
@media screen and (max-width: 576px) {
    p {
        font-size: 14px;
    }
}
