.MuiTabs-flexContainer {
  /* justify-content: space-evenly; */
  font-weight: bold;
}

.MuiTabs-flexContainer .MuiTab-root {
  font-weight: bold;
  font-size: 20px;
}
.MuiSwitch-switchBase .MuiSwitch-thumb {
  background-color: var(--primary);
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--primary) !important;
}
input::placeholder,
.MuiSelect-select::placeholder {
  font-weight: 500 !important;
}
input,
.MuiSelect-select {
  font-weight: bold !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px 20px;
  background-color: var(--primary);
  opacity: 0.5;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #848484;
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  background-color: #d5d5d5;
}

.MuiDialog-container .MuiPaper-root {
  margin-left: auto;
  margin-right: 70px;
}
.map-dialog .MuiDialogContent-root {
  padding: 0px !important;
}
.map-dialog .MuiPaper-root {
  margin: auto;
  width: 1000px;
  max-width: calc(100% - 60px);
  overflow: initial;
}
.map-dialog .MuiPaper-root .close-icon {
  position: absolute;
  right: -25px;
  top: -20px;
  color: white;
  cursor: pointer;
}


.list-dialog .MuiDialogContent-root {
  padding: 0px !important;
}
.list-dialog .MuiPaper-root {
  margin: auto;
  width: 1500px;
  max-width: calc(100% - 60px);
  overflow: initial;
}
.list-dialog .MuiPaper-root .close-icon {
  position: absolute;
  right: -25px;
  top: -20px;
  color: white;
  cursor: pointer;
}

.MuiList-root .MuiMenuItem-root {
  padding: 12px 20px;
}
.MuiList-root .MuiMenuItem-root:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.modal-in-center .MuiPaper-root {
  margin: auto;
}
h3.required {
  position: relative;
  display: inline-block;
  width: fit-content;
}
h3.required::after {
  content: "*";
  position: absolute;
  right: -9px;
  top: -2px;
  font-size: 20px;
  color: red;
  line-height: 1.2;
}

/* @media screen and (min-width: 1600px) {
  .MuiFormHelperText-root.Mui-error,
  .error-text {
      font-size: 18px !important;
      top: -35px;
  }
  input,
  .MuiSelect-select {
      font-size: 21px !important;
      padding: 24px !important;
  }
  .MuiInput-underline input,
  .MuiSelect-select {
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
} */

@media screen and (max-width: 576px) {
  .MuiTextField-root input,
  .MuiSelect-select,
  .react-datepicker-wrapper input {
    padding: 12px 14px !important;
    font-size: 14px !important;
  }
  .MuiSelect-select + input {
    padding: 14px 14px !important;
    font-size: 14px !important;
  }
}

.react-datepicker__input-container input {
  padding: 15px 15px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  border: 1px solid #c4c4c4;
  outline: none;
}
.pac-logo {
  z-index: 9999;
}

.MuiMenu-list{
  max-height: 250px;
}
