.add-details-info-wrapper {
    display: flex;
    justify-content: space-between;
}

.dashboard-btn.subscription-detail {
    margin: 0;
}
.back-link img {
    width: 10px;
    margin-right: 14px;
    cursor: pointer;
}
