.formItem.add-details-info.add-subs {
  margin-bottom: 50px;
  margin-top: 0;
}

.add-details-info-wrapper {
  padding-bottom: 50px;
}

.back-link img {
  width: 10px;
  margin-right: 14px;
  cursor: pointer;
}

.invite-dialog-cover .MuiDialog-container .MuiPaper-root {
  margin-right: auto;
}

h5.add {
  color: #2d2fc1;
  text-decoration: underline;
  cursor: pointer;
}

.item {
  margin-top: 24px;
}
