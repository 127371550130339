.csv-file-upload {
  height: calc(100vh - 200px);
  width: 600px;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  text-align: center;
  background-color: #f5f5f5;
  font-size: 18px;
  color: #555555;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  height: 200px;
  margin: 28px 0;
}

.upload-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

.browse-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.filter-cover {
  width: 500px;
  height: auto;
  max-width: 100%;
}
.filter-cover .filter-header {
  background-color: var(--primary);
  color: var(--white);
  padding: 13px 40px 13px 25px;
  position: relative;
}
.filter-cover .filter-header .close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  font-size: 26px;
  cursor: pointer;
  transform: translateY(-50%);
}
.filter-cover .filter-body {
  padding: 30px 25px;
  overflow-y: auto;
  max-height: calc(100vh - 212px);
}
@media screen and (min-width: 1600px) {
  .filter-cover .filter-body {
    padding: 30px 25px;
    overflow-y: auto;
    max-height: calc(100vh - 221px);
  }
}
@media screen and (max-width: 576px) {
  .filter-cover .filter-body {
    padding: 20px 15px;
  }
  .filter-cover .filter-header {
    padding: 11px 40px 11px 15px;
  }
}
.filter-cover .filter-footer {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8e8e8;
  border-top: 1px solid #dcdcdc;
}

.progressBarStyle {
  width: '100%';
  height: '20px';
  background-color: '#e0e0e0';
}

.failedInfo {
  display: flex;
  flex-direction: column;
}

.truncate {
  width: 390px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.li {
  list-style: disc;
  margin: 10px 0 0 30px;
}
