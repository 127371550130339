.formItemList {
    padding-bottom: 50px;
}

.user-card h5 {
    font-weight: 700;
    font-size: 18px;
    color: var(--primary);
    padding-bottom: 15px;
}

.user-card h2 {
    font-weight: 400;
    font-size: 20px;
    color: var(--primary);
}

.user-password .user-password-change h5 {
    font-weight: 500;
    font-size: 20px;
    color: var(--primary);
}